<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto" style="max-width: fit-content;">
      <div style="display: flex">
        <h1
          style="width: calc(100% - 100px);"
          class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
        >
          Today's Sales
        </h1>
        <div style="display: flex">
          <select
            class="form-select"
            @change="rangeSelection"
            style="color: #4a5568;
            padding: 0.5rem 1.7rem;
            line-height: 1.25;
            border-width: 2px;
            border-radius: 0.25rem;
            border-color: #007fff;
            background-color: #edf2f7;
            margin-bottom: 4px;"
          >
            <option value="">Select Date Range</option>
            <option value="3">3 days</option>
            <option value="7">7 days</option>
            <option value="30">30 days</option>
          </select>

          <select
            class="form-select"
            @change="category"
            style="color: #4a5568;
          padding: 0.5rem 1.7rem;
          line-height: 1.25;
          border-width: 2px;
          border-radius: 0.25rem;
          border-color: #007fff;
          background-color: #edf2f7;
          margin-bottom: 4px;"
          >
            <option value="">Select Category</option>
            <option value="Sports">Sports</option>
            <option value="Concerts">Concerts</option>
            <option value="Other">Other</option>
            <option value="Theatre">Theatre</option>
          </select>
        </div>
      </div>

      <div class="mb-4">
        <!-- component -->
        <div id="recipients" class="">
          <table
            id="tickets"
            class="border-collapse stripe hover w-full text-sm"
            style="overflow: scroll;
            overflow-x: scroll;
            display: block;
            position: initial;"
            v-if="data.length"
          >
            <thead>
              <tr>
                <th data-priority="1">
                  Event
                </th>
                <th data-priority="2">
                  TM Link
                </th>
                <th data-priority="3">
                  TP Link
                </th>
                <th data-priority="4">
                  Favourite
                </th>

                <th data-priority="14">
                  Venue Name
                </th>

                <th data-priority="15">
                  City
                </th>
                <th data-priority="16">
                  State
                </th>

                <th data-priority="18">
                  Category
                </th>

                <th data-priority="17">
                  Available tix
                </th>

                <th data-priority="21">
                  Avg Value
                </th>
                <th data-priority="22">
                  Last Event Date
                </th>
                <th data-priority="22">
                  Dates
                </th>

                <th data-priority="24">
                  Tix Sold In Date
                </th>
                <th data-priority="25">
                  Avg Sale Price
                </th>
                <th data-priority="26">
                  Time
                </th>
                <th data-priority="27">
                  id
                </th>
                <th data-priority="28">
                  venueid
                </th>
              </tr>
            </thead>
            <tbody id="ticketsList">
              <tr v-for="tr in data" :key="tr.id" id="tix-data-wait">
                <td>{{ tr.event }}</td>
                <td>{{ tr.event }}</td>
                <td>{{ tr.event }}</td>
                <td>{{ tr.favorite }}</td>

                <td>{{ tr.venuename }}</td>
                <td>
                  {{ tr.city }}
                </td>
                <td>
                  {{ tr.state }}
                </td>

                <td>
                  {{ tr.category }}
                </td>
                <td>
                  {{ tr.availabletixs }}
                </td>

                <td>
                  {{ tr.avgvalue }}
                </td>
                <td>
                  {{ tr.lasteventdate }}
                </td>
                <td>
                  {{ tr.dates }}
                </td>
                <td>
                  {{ tr.tixsoldindaterange }}
                </td>
                <td>
                  {{ tr.avgsaleprice }}
                </td>
                <td>
                  {{ tr.createdat }}
                </td>
                <td>{{ tr.id }}</td>
                <td>{{ tr.venueid }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!data.length" class="text-center">
            {{ loading }}
          </div>

          <div><span id="datetime12"></span></div>
        </div>
      </div>
    </div>
  </main>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
<script>
import * as moment from "moment";
import jQuery from "jquery";
export default {
  name: "NewTickets",
  data() {
    return {
      loading: "Please wait...",
      data: [],
      username: "",
      addLoading: -1,
      daterange: ""
    };
  },
  created() {
    this.get();
    //this.datetime();
  },
  methods: {
    addBookmark(item, i) {
      this.addLoading = i;
      this.$axios
        .post(`${this.$api}/bookmark/add`, {
          userId: localStorage.getItem("user-id"),
          event: item.event
        })
        .then(res => {
          alert(res.data.message);
          this.addLoading = -1;
        })
        .catch(err => {
          this.addLoading = -1;
          console.log(err);
          alert("Something went wrong, please try again");
        });
    },

    dateParse(dt) {
      return moment(moment(dt).utcOffset("-06:00")).format(
        "MMMM Do YYYY, hh:mm A"
      );
    },
    dateParse1(dt1) {
      return moment(moment(dt1)).format("dddd");
    },

    rangeSelection(e) {
      console.log(e.target.value);
      this.daterange = e.target.value;
      this.data = [];
      this.jQuery("#tickets")
        .DataTable()
        .destroy();
      this.loading = "Please wait...";
      this.get();
    },
    category(e) {
      console.log(e.target.value);
      this.daterange = e.target.value;
      this.data = [];
      this.jQuery("#tickets")
        .DataTable()
        .destroy();
      this.loading = "Please wait...";
      this.get();
    },
    addCustomSelect() {
      const parentGuest = document.getElementById("tickets_filter");
      var array = ["", 3, 7, 30];
      //Create and append select list
      var selectList = document.createElement("select");
      selectList.style.float = "right";
      selectList.style.marginRight = "15px";
      selectList.id = "rangeSelection";
      selectList.setAttribute("v-on:change", "rangeSelection()");
      parentGuest.parentNode.insertBefore(selectList, parentGuest.nextSibling);
      //Create and append the options
      for (var i = 0; i < array.length; i++) {
        var option = document.createElement("option");
        option.value = array[i];
        option.text = array[i] == "" ? "Select Date Range" : array[i] + " days";
        selectList.appendChild(option);
      }
    },

    get() {
      const daterangeVal = this.daterange ? `?daterange=${this.daterange}` : "";
      this.$axios
        .get(`${this.$api}/ticket/get${daterangeVal}`)
        .then(res => {
          this.data = res.data.data;
          console.log(this.data);
          if (this.data.length === 0) {
            this.loading = "No data found";
          }
          if (!res.data.success) {
            this.loading = "No data found";
          }
          //Radhika - change
          // console.log("inside then newtickets");
          // this.data.sort((a, b) => {
          //   console.log(a.tixsoldindaterange + " " + b.tixsoldindaterange);
          //   // console.log(b.tixsoldindaterange);
          //   if (a.tixsoldindaterange < b.tixsoldindaterange) return 1;
          //   else if (a.tixsoldindaterange > b.tixsoldindaterange) return -1;
          // });
          var self = this;
          var existTix = setInterval(() => {
            if (self.jQuery("#tix-data-wait").length) {
              clearInterval(existTix);
              var pTable = self.jQuery("#tickets").DataTable({
                retrieve: true,
                deferRender: true,
                responsive: true,
                aLengthMenu: [50],
                aaSorting: [[8, "desc"]],
                aoColumnDefs: [
                  {
                    bSortable: false,
                    aTargets: [-14, -15]
                  }
                ],
                processing: true,
                language: {
                  processing:
                    "<i class='fa fa-refresh fa-spin' style='font-size:4em;margin-top:20%;'></i>"
                },
                serverSide: true,
                ajax: {
                  url: `${self.$api}/ticket/get`,
                  data: { daterange: self.daterange },
                  dataFilter: function(data) {
                    console.log("this.data");
                    console.log(this.data);
                    const records = JSON.parse(data);
                    const json = records.data;
                    console.log(json);
                    console.log("json search");
                    return JSON.stringify({
                      recordsTotal: records.total,
                      recordsFiltered: records.total,
                      data: json
                    }); // return JSON string
                  }
                },
                // drawCallback: function (responseData) {
                //   console.log(responseData.json.data);
                //   return responseData.json.data;
                // },
                deferLoading: res.data.total,
                columns: [
                  {
                    data: "event",
                    title: "Event",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/ticket-chart/${data}/${row.city}/${row.category}"
                        target="_blank"
                        >${data}</a
                      >`;
                    }
                  },
                  {
                    data: "event",
                    title: "TM Link",
                    render: function(data, _type, row) {
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/test/${data}/${row.city}/${row.state}"
                        target="_blank"
                        ><img src="${require("@/assets/img/landing/ticket.jpeg")}" alt="img" style="max-width: 30px;"></a
                      >`;
                    }
                  },
                  {
                    data: "event",
                    title: "TP Link",
                    render: function(data, _type, row) {
                      name = data.replace("/", "-");
                      return `<a
                        class="cursor-pointer text-blue-600"
                        href="/admin/test1/${name}"
                        target="_blank"
                        ><img src="${require("@/assets/img/landing/tickpick.png")}" alt="img" style="max-width: 30px;"></a
                      >`;
                    }
                  },
                  {
                    data: "favorite",
                    title: "Favourite",

                    render: function(data, _type, row) {
                      var data2 = row.event || "0"; // Default to "0" if row.event is empty
                      var name2 = encodeURIComponent(data2.replace("/", "-")); // Properly encode the event name

                      var lasteventdate = row.lasteventdate || "0"; // Default to "0" if row.lasteventdate is empty
                      lasteventdate = encodeURIComponent(lasteventdate.replaceAll("/", "-")); // Encode last event date

                      return data == 0
                        ? `<a href="/admin/test2/${row.id}/${row.venueid}/${
                            row.venuename
                          }/${row.city}/${
                            row.state
                          }/${name2}/${row.performerid || "0"}/${
                            row.category
                          }/${row.availabletixs}/${row.totalvalue}/${
                            row.avgvalue
                          }/${lasteventdate}/${row.tixsoldindaterange}/${
                            row.avgsaleprice
                          }/${row.createdat}/${
                            row.isactive
                          }"><i class='fa fa-heart btn-edit'" id='solTitle' style='font-size:2em;'></i></a>`
                        : `<a href="/admin/newtest2/${row.id}"><i class='fa fa-heart' style='font-size:2em; color:red;'></i></a>`;
                    }
                  },
                  {
                    data: "venuename",
                    title: "venuename",

                    render: function(data, _type, row) {
                      return `<a href="/admin/test5/${row.venuename}">${row.venuename}</a>`;
                    }
                  },
                  // { data: "venuename" },
                  { data: "city" },
                  { data: "state" },
                  { data: "category" },
                  { data: "availabletixs" },
                  { data: "avgvalue" },
                  {
                    data: "lasteventdate",
                    title: "Last Event Date",
                    render: function(data) {
                      return self.dateParse1(data) + " " + data;
                    }
                  },

                  { data: "dates" },
                  { data: "tixsoldindaterange" },
                  { data: "avgsaleprice" },
                  {
                    data: "createdat",
                    title: "Time",
                    render: function(data) {
                      return self.dateParse(data);
                    }
                  },
                  { data: "id" },
                  { data: "venueid" }
                ]
              });
              // var pTable = $('#pTable').DataTable();
              self
                .jQuery(".dataTables_filter input")
                .unbind()
                .bind("keyup change", function(e) {
                  if (e.keyCode == 13 || this.value == "") {
                    pTable.search(this.value).draw();
                  }
                });
            }

            // this.addCustomSelect();
          }, 50);
        })
        .catch(err => {
          console.log(err);
          this.loading = "Something went wrong, please try again";
        });
    },
    deleteMode(trid) {
      //alert(JSON.stringify(tr));
      this.$axios
        .post(`https://varilytics.com/api/deletefavourite_events_new`, {
          id: trid,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          }
        })
        .then(result => {
          //var re = JSON.parse(result);
          alert(result.data.message);
          window.location.reload();
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
        })
        .catch(error => console.log("error", error));
    },
    editMode(tr) {
      alert(JSON.stringify(tr));
      console.log("tr");
      // console.log(tr);
      this.username1;
      var user_id = localStorage.getItem("user-id");

      //alert(user_id);
      this.$axios
        .get(`${this.$api}/username/${user_id}`)
        .then(res => {
          //alert(res.data);
          //console.log(res.data.data[0].username);
          this.username1 = res.data.data[0].username;
          console.log(this.username1);
          //this.addLoading = -1;
          this.$axios
            .post(`https://varilytics.com/api/addfavourite_events`, {
              userid: this.username1,
              eventid: tr.id,
              venueid: tr.venueid,
              venuename: tr.venuename,
              city: tr.city,
              state: tr.state,
              event: tr.event,
              performerid: tr.performerid,
              category: tr.category,
              availabletixs: tr.availabletixs,
              totalvalue: tr.totalvalue,
              avgvalue: tr.avgvalue,
              lasteventdate: tr.lasteventdate,
              dates: tr.dates,
              tixsoldindaterange: tr.tixsoldindaterange,
              avgsaleprice: tr.avgsaleprice,
              createdate: tr.createdat,
              isactive: tr.isactive,
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token"
              }
            })
            .then(result => {
              //var re = JSON.parse(result);
              alert(result.data.message);
              window.location.reload();
              //alert(re.message);
              //this.fa-heart.color = this.colors.green;
            })
            .catch(error => console.log("error", error));
        })
        .catch(err => {
          //this.addLoading = -1;
          console.log(err);
          //alert("Something went wrong, please try again");
        });
    },

    datetime() {
      var currentDate = new Date();
      var html = "";
      // const date = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const time = currentDate.getHours() + ":" + currentDate.getMinutes();
      console.log(time);
      if (
        currentDate.getHours() == 8 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 8 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 8) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 08:45 (next update 09:15)";
        }
      } else if (
        currentDate.getHours() == 9 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 9 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 9) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 09:45 (next update 10:15)";
        }
      } else if (
        currentDate.getHours() == 10 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 10 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 10) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 10:45 (next update 11:15)";
        }
      } else if (
        currentDate.getHours() == 11 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 11 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 11) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 11:45 (next update 12:15)";
        }
      } else if (
        currentDate.getHours() == 12 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 12 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 12) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 12:45 (next update 13:15)";
        }
      } else if (
        currentDate.getHours() == 13 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 13 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 13) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 13:45 (next update 14:15)";
        }
      } else if (
        currentDate.getHours() == 14 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 14 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 14) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 14:45 (next update 15:15)";
        }
      } else if (
        currentDate.getHours() == 15 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 15 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 15) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 15:45 (next update 16:15)";
        }
      } else if (
        currentDate.getHours() == 16 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 16 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 16) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 16:45 (next update 17:15)";
        }
      } else if (
        currentDate.getHours() == 17 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 17 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 17) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 17:45 (next update 18:15)";
        }
      } else if (
        currentDate.getHours() == 18 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 18 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      } else if (currentDate.getHours() >= 18 && currentDate.getHours() <= 20) {
        if (currentDate.getMinutes() <= 45 || currentDate.getMinutes() >= 15) {
          html = "Last update completed 18:45 (next update 19:15)";
        }
      } else if (
        currentDate.getHours() == 19 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 19 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 19) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 19:45 (next update 20:15)";
        }
      } else if (
        currentDate.getHours() == 20 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 20 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 20) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 20:45 (next update 21:15)";
        }
      } else if (
        currentDate.getHours() == 21 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 21 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 21) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 21:45 (next update 22:15)";
        }
      } else if (
        currentDate.getHours() == 22 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 22 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 22) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 22:45 (next update 23:15)";
        }
      } else if (
        currentDate.getHours() == 23 &&
        currentDate.getMinutes() >= 15 &&
        currentDate.getHours() == 23 &&
        currentDate.getMinutes() <= 45
      ) {
        html = "Update in progress";
      }
      if (currentDate.getHours() == 23) {
        if (currentDate.getMinutes() >= 45 || currentDate.getMinutes() <= 15) {
          html = "Last update completed 23:45 (next update 08:15)";
        }
      } else if (
        currentDate.getHours() >= 23 &&
        currentDate.getMinutes() <= 45 &&
        currentDate.getHours() <= 8 &&
        currentDate.getMinutes() >= 15
      ) {
        // var hours = currentDate.getHours() - 23;
        // var min = currentDate.getMinutes() - 15;
        // // var difftime =  min;
        // var difftime = hours + ":" + min;
        // console.log(difftime);
        // html += "<h6>Last update completed 8:45 am (next update "+difftime+" min)</h6>";
        html += "Last update completed 23:45 (next update 08:15)";
      }
      console.log(html);
      // document.getElementById('datetime12').innerHTML = "<h6>Last update completed 23:45 (next update 08:15)</h6>";
      // console.log(document.getElementById('datetime12'));
      //document.getElementById('datetime').text = "<h6>Last update completed 23:45 (next update 08:15)</h6>";
      //console.log(document.getElementById('datetime').text);
      $(document).ready(function() {
        //$('#datetime12').innerText="<h6>Last update completed 23:45 (next update 08:15)</h6>";
        console.log($("#datetime12").text(html));
      });
    }
  }
};
// $(document).ready(function() {
//   //alert("hh");
//   var myEl = document.getElementById("solTitle");
//   alert(myEl);
//   $("#solTitle").on("click",function() {
//     alert("hh");
//   });
//   jQuery(".btn-edit").on("click", function() {
//     alert("hh");
//   });
// });
</script>

<style scoped>
/*Overrides for Tailwind CSS */

/*Form fields*/
.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568; /*text-gray-700*/
  padding-left: 1rem; /*pl-4*/
  padding-right: 1rem; /*pl-4*/
  padding-top: 0.5rem; /*pl-2*/
  padding-bottom: 0.5rem; /*pl-2*/
  line-height: 1.25; /*leading-tight*/
  border-width: 2px; /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7; /*border-gray-200*/
  background-color: #edf2f7; /*bg-gray-200*/
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 3px !important;
}
table.dataTable {
  width: 100% !important;
  /*margin-left: -90px;*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #ebf4ff; /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important; /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /*shadow*/
  font-weight: 700; /*font-bold*/
  border-radius: 0.25rem; /*rounded*/
  background: #667eea !important; /*bg-indigo-500*/
  border: 1px solid transparent; /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  border-bottom: 1px solid #e2e8f0; /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important; /*bg-indigo-500*/
}
.r-1sreavd {
  fill: rgb(249, 24, 128);
}
.r-1xvli5t {
  height: 2em;
}
</style>
